"use strict";
import Plyr from "plyr";

class PlyrInit {
    constructor() {
        this.init();
    }

    init() {
        const players = document.querySelectorAll("[data-plugin-plyr]");

        if (players.length > 0) {
            players.forEach((player) => {
                const plyrInstance = new Plyr(player, {
                    controls: [
                        "play-large",
                        "play",
                        "progress",
                        "current-time",
                        "mute",
                        "volume",
                        "captions",
                        "fullscreen",
                    ],
                    fullscreen: {enabled: true, fallback: true, iosNative: true},
                    youtube: {
                        noCookie: true,
                    },
                    ratio: "16:9",
                    keyboard: {
                        focused: true,
                    },
                });

                // instead of setting time with media fragmets, we set it here to avoid many different implementations for audio, html5-video and youtube
                // downside is that this depends on client
                // Header of "Accept-Range" must be set to bytes in server config
                if (plyrInstance.isEmbed || plyrInstance.isHTML5) {
                    plyrInstance.once("ready", onDataLoaded);
                }

                function onDataLoaded(event) {
                    const plyr = event.detail.plyr;
                    const startTime = player.dataset.startTime;

                    if (startTime) {
                        plyr.currentTime = parseInt(startTime);
                    }
                }

                return plyrInstance;
            });
        }
    }
}

window.addEventListener('load', function() {
    // check if there are any plyr videos on the current page
    if (document.querySelector('[data-plugin-plyr]')) {
        new PlyrInit();
    }
});
